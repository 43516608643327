import React, { useState, useContext } from 'react';
import './App.css';
import './styles/style.css';
import './styles/bootstrap.css';
import './fonts/css/fontawesome-all.min.css';
import '../node_modules/react-resizable/css/styles.css'
import '../node_modules/react-grid-layout/css/styles.css'
import { UserContext } from './App';
import { Link, Navigate } from 'react-router-dom';

function Register() {

    const { user, setUser } = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    if (redirect) return <Navigate to='/' replace></Navigate>
    const register = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(loginData)
        };
        fetch(`${process.env.REACT_APP_API_URL}auth/register`, requestOptions)
            .then(response => response.json())
            .then(data => { setUser({ token: data.token, user: data.user }); setRedirect(true) });
    }

    return <>
        <div className="card-center" style={{ paddingLeft: '30%', paddingRight: '30%' }}>
            <div className="ps-5 pe-5">
                <h1 className="text-center font-800 font-40 mb-1">Register</h1>
                <p className="color-highlight text-center font-12">Sign up for a new account</p>

                <div className="input-style no-borders has-icon validate-field">
                    <i className="fa fa-user"></i>
                    <input value={loginData.email} onChange={(e) => setLoginData({ ...loginData, email: e.target.value })} type="email" className="form-control validate-name" id="form1a" placeholder="Email" />
                    <label className="color-blue-dark font-10 mt-1">Email</label>
                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                    <i className="fa fa-check disabled valid color-green-dark"></i>
                    <em>(required)</em>
                </div>

                <div className="input-style no-borders has-icon validate-field mt-4">
                    <i className="fa fa-lock"></i>
                    <input value={loginData.password} onChange={(e) => setLoginData({ ...loginData, password: e.target.value })} type="password" className="form-control validate-password" id="form3a" placeholder="Password" />
                    <label className="color-blue-dark font-10 mt-1">Password</label>
                    <i className="fa fa-times disabled invalid color-red-dark"></i>
                    <i className="fa fa-check disabled valid color-green-dark"></i>
                    <em>(required)</em>
                </div>

                <div className="d-flex mt-4 mb-4">
                    <div className="w-50 font-11 pb-2 text-start"><Link to='/login'>Login</Link></div>
                </div>

                <a onClick={() => register()} className="back-button btn btn-full btn-m shadow-large rounded-sm text-uppercase font-700 bg-highlight">SUBMIT</a></div>
        </div>
    </>
}

export default Register;
