import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import './styles/style.css';
import './styles/bootstrap.css';
import './fonts/css/fontawesome-all.min.css';
import '../node_modules/react-resizable/css/styles.css'
import '../node_modules/react-grid-layout/css/styles.css'
import RGL, { WidthProvider } from "react-grid-layout";
import { UserContext } from './App';

const blockEditTemplates = [{ type: "basic-card", elements: ["text", "text", "textarea"] },
{ type: "image-card", elements: ["image", "text", "text"] },
{ type: "event", elements: ["image", "text", "text", "text", "text", "text"] },
{ type: "meeting", elements: ["text", "text", "text", "text"] }];

const API_URL = process.env.API_URL;

const GridLayout = WidthProvider(RGL);

function Editor() {
    const { user, setUser } = useContext(UserContext);
    const [selectedBlock, setSelectedBlock] = useState(null as any);
    const [apps, setApps] = useState([] as any[]);
    const [appData, setAppData] = useState({} as any);
    const [pages, setPages] = useState([] as any[]);
    const [pageData, setPageData] = useState({} as any);
    const [displayUpgradeModal, setDisplayUpgradeModal] = useState(false);
    const [displayRequestedInfoModal, setDisplayRequestedInfoModal] = useState(false);
    const saveLayout = async (e: any) => {
        //todo
    }

    useEffect(() => {
        loadApps();
    }, []);

    const loadApps = async () => {
        const requestOptions = {
            method: 'GET',
            headers: { Authorization: "Bearer " + user.token, },
        };
        fetch(`${process.env.REACT_APP_API_URL}editor`, requestOptions)
            .then(response => response.json())
            .then(data => { setApps(data); if (!appData || !appData._id) loadApp(data[0]._id); });;
    }

    const saveApp = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + user.token },
            body: JSON.stringify({ object: appData })
        };
        fetch(`${process.env.REACT_APP_API_URL}editor`, requestOptions).then(response => response.json())
            .then(data => { loadApps(); });
    }

    const savePage = async (reloadPageList?: boolean) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + user.token },
            body: JSON.stringify({ object: pageData })
        };
        fetch(`${process.env.REACT_APP_API_URL}editor/page`, requestOptions).then(response => response.json())
            .then(data => { if (reloadPageList) loadPages(appData._id); });
    }

    const savePageOnReorder = async (obj: any) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + user.token },
            body: JSON.stringify({ object: obj })
        };
        fetch(`${process.env.REACT_APP_API_URL}editor/page`, requestOptions);
    }

    const loadPage = async (id: string) => {
        savePage();
        setSelectedBlock({} as any);
        const requestOptions = {
            method: 'GET',
            headers: { Authorization: "Bearer " + user.token },
        };
        fetch(`${process.env.REACT_APP_API_URL}editor/page/${id}`, requestOptions)
            .then(response => response.json())
            .then(data => { setPageData(data); });
    }

    const requestCallback = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/form-data',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ type: 'More info requested from MVP builder', user: user.user })
        };
        fetch(`https://api.verticode.co.uk/form`, requestOptions)
            .then(data => { setDisplayRequestedInfoModal(true); setDisplayUpgradeModal(false) });
    }

    const addApp = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { Authorization: "Bearer " + user.token },
        };
        fetch(`${process.env.REACT_APP_API_URL}editor/app`, requestOptions)
            .then(response => response.json())
            .then(data => { setAppData(data); loadApps(); });
    }

    const addPage = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { Authorization: "Bearer " + user.token },
        };
        fetch(`${process.env.REACT_APP_API_URL}editor/page/${appData._id}`, requestOptions)
            .then(response => response.json())
            .then(data => { setPageData(data); loadPages(appData._id); });
    }

    const loadApp = async (id: string) => {
        savePage();
        setPageData({} as any);
        setSelectedBlock({} as any);
        const requestOptions = {
            method: 'GET',
            headers: { Authorization: "Bearer " + user.token },
        };
        fetch(`${process.env.REACT_APP_API_URL}editor/${id}`, requestOptions)
            .then(response => response.json())
            .then(data => { setAppData(data); loadPages(id, true); });
    }

    const loadPages = async (id: string, forceLoadFirst?: boolean) => {
        const requestOptions = {
            method: 'GET',
            headers: { Authorization: "Bearer " + user.token },
        };
        fetch(`${process.env.REACT_APP_API_URL}editor/pages/${id}`, requestOptions)
            .then(response => response.json())
            .then(data => { setPages(data); if (forceLoadFirst || !pageData || !pageData._id) loadPage(data[0]._id) });
    }

    const addBlock = (type: string) => {
        let newId = pageData.elements.length > 0 ? Math.max(...pageData.elements.map((o: any) => o.id)) + 1 : 0;
        let maxY = pageData.layout.length > 0 ? Math.max(...pageData.layout.map((o: any) => o.y)) : 0;

        let content = [] as any[];
        if (type === "basic-card") {
            content = ["Lorem Ipsum", "Lorem ipsum dolor sit amet, consectetur adipiscing elit", "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."]
        }
        if (type === "image-card") {
            content = ["https://source.unsplash.com/random", "Lorem Ipsum", "Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
        }
        if (type === "event") {
            content = ["https://source.unsplash.com/random", "August", "10am", "London", "Lorem Ipsum", "Dolor sit amet, consectetur adipiscing elit", "Button Text"]
        }
        if (type === "meeting") {
            content = ["Lorem ipsum", "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.", "January", "11:00 AM"]
        }
        setPageData({
            ...pageData, layout: [...pageData.layout, { w: 4, h: 8, i: `block${newId}`, y: maxY + 1, x: 0 }], elements: [...pageData.elements, {
                id: newId,
                type: type, content: content
            }]
        })
    }

    const renderBlock = (block: any) => {
        if (!block) return <></>
        if (block.type === "basic-card") {
            return <>
                <div className="card card-style rounded-m no-user-select" onClick={() => setSelectedBlock(block)}>
                    <div className="content mb-0">
                        <h1 className="text-center mb-0">{block.content[0]}</h1>
                        <p className="text-center color-highlight font-11 mt-n1">{block.content[1]}</p>
                        <p className="boxed-text-xl mt-n3">
                            {block.content[2]}
                        </p>
                    </div>
                </div>
            </>
        }
        if (block.type === "image-card") {
            return <>
                <div className="card card-style rounded-m shadow-l mx-3 no-user-select" onClick={() => setSelectedBlock(block)}>
                    <div className="card-bottom text-center mb-0">
                        <h1 className="color-white font-700 mb-n1">{block.content[1]}</h1>
                        <p className="color-white opacity-80 mb-4">{block.content[2]}</p>
                    </div>
                    <div className="card-overlay bg-gradient"></div>
                    <img className="img-fluid" src={block.content[0]} />
                </div>
            </>
        }
        if (block.type === "event") {
            return <>
                <div className="card card-style  no-user-select rounded-m" onClick={() => setSelectedBlock(block)}>
                    <div data-card-height="200" className="card shadow-l mb-0" style={{ height: '150px', backgroundImage: `url(${block.content[0]})` }}>
                        <div className="card-bottom ms-3">
                            <p className="color-white font-10 opacity-80 mb-n1"><i className="far fa-calendar"></i> {block.content[1]} <i className="ms-3 far fa-clock"></i> {block.content[2]}</p>
                            <p className="color-white font-10 opacity-80 mb-2"><i className="fa fa-map-marker-alt"></i> {block.content[3]}</p>
                        </div>
                        <div className="card-overlay bg-gradient opacity-90 rounded-0"></div>
                    </div>
                    <div className="content mb-0">
                        <div className="float-start">
                            <h1 className="mb-n1">{block.content[4]}</h1>
                            <p className="font-10 mb-2 pb-1">{block.content[5]}</p>
                        </div>
                    </div>
                </div></>
        }
        if (block.type === "meeting") {
            return <>
                <div className="card card-style no-user-select rounded-m" onClick={() => setSelectedBlock(block)}>
                    <div className="content mb-4">
                        <h3>{block.content[0]}</h3>
                        <p>
                            {block.content[1]}
                        </p>
                        <div className="divider mb-3"></div>
                        <div className="row mb-0">
                            <div className="col-6">
                                <p className="text-center mb-0 pb-3"><i className="fa fa-calendar-alt me-2 color-yellow-dark"></i>{block.content[2]}</p>
                            </div>
                            <div className="col-6">
                                <p className="text-end mb-0 pb-3"><i className="fa fa-clock me-2 color-green-dark"></i>{block.content[3]}</p>
                            </div>
                        </div>
                    </div>
                </div></>
        }
    }

    const updateSelectedBlockContent = (value: any, index: number) => {
        let block = pageData.elements.find((x: any) => x.id == selectedBlock.id);
        let content = [...block.content];
        content[index] = value;
        block.content = content;
        setPageData({ ...pageData, elements: [...pageData.elements] });
    }

    const updateSelectedBlockLink = (value: any) => {
        let block = pageData.elements.find((x: any) => x.id == selectedBlock.id);
        block.link = value;
        setPageData({ ...pageData, elements: [...pageData.elements] });
    }

    const deleteElement = () => {
        let eles = [...pageData.elements];
        let eleIndex = eles.findIndex((x: any) => x.id === selectedBlock.id)
        eles.splice(eleIndex, 1);
        console.log("eles", eles);
        let layoutData = [...pageData.layout];
        let layoutIndex = layoutData.findIndex((x: any) => x.i === `block${selectedBlock.id}`);
        layoutData.splice(layoutIndex, 1);
        console.log("indexes", `ele - ${eleIndex} |||| layout - ${layoutIndex}`)
        console.log("layoutData", layoutData);
        setPageData({ ...pageData, elements: [...eles], layout: [...layoutData] });
        setSelectedBlock({} as any);
    }

    const renderEditForm = () => {
        if (!selectedBlock) return <></>;
        let template = blockEditTemplates.find((x: any) => x.type === selectedBlock.type);
        return <div key={selectedBlock.id}>
            {template?.elements.map((x: any, index: number) => {
                if (x === "text") {
                    return <div className="input-style input-style-always-active has-borders">
                        <input onBlur={() => savePage()} type="text" className="form-control" value={selectedBlock.content[index]} onChange={(event: any) => updateSelectedBlockContent(event.target.value, index)} />

                    </div>
                }
                if (x === "textarea") {
                    return <div className="input-style input-style-always-active has-borders">
                        <textarea onBlur={() => savePage()} rows={6} className="form-control" value={selectedBlock.content[index]} onChange={(event: any) => updateSelectedBlockContent(event.target.value, index)} ></textarea>
                    </div>
                }
                if (x === "image") {
                    return <div className="input-style input-style-always-active has-borders">
                        <input onBlur={() => savePage()} type="url" className="form-control" value={selectedBlock.content[index]} onChange={(event: any) => updateSelectedBlockContent(event.target.value, index)} />
                        <label className="color-blue-dark font-13">Image URL</label>
                    </div>
                }
            })}
            <div className="input-style input-style-always-active has-borders">
                <select onBlur={() => savePage()} value={selectedBlock.link} onChange={(event: any) => updateSelectedBlockLink(event.target.value)} className="form-control">
                    <option value={''}></option>
                    {pages.map((x: any) => {
                        return <option value={x._id}>{x.name}</option>
                    })}
                </select>
                <label className="color-blue-dark font-13">Link</label>
            </div>
            <button className="btn bg-highlight text-uppercase font-900 btn-m btn-full rounded-sm shadow-xl" onClick={() => deleteElement()}>Delete</button>
        </div>
    }

    return (
        <>
            <div className="header-bar">
                <div className="logo-box" style={{
                    fontSize: '20px',
                    fontWeight: '700',
                    marginTop: '20px'
                }}>MVP Builder</div>
                <a onClick={() => setDisplayUpgradeModal(true)} style={{ float: 'right' }} className="m-3 btn btn-full btn-m font-900 text-uppercase rounded-sm shadow-l bg-green-dark">Upgrade Now</a>
                <a style={{ float: 'right' }} target="_blank" href={`https://view.mvpbuilder.app/${appData._id}`} className="m-3 btn btn-full btn-m font-900 text-uppercase rounded-sm shadow-l bg-blue-dark">View Live App</a>
            </div>
            <div className="site-container">

                <div className="pages-bar">
                    <div style={{ width: '350px', overflow: 'scroll', maxHeight: '100%' }}>

                        <div style={{ margin: '15px' }}>
                            <h5 className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0" style={{ color: 'white', textAlign: 'left' }}>App Name</h5>
                            <div className="input-style input-style-always-active has-borders">
                                <input type="text" className="form-control" value={appData.name} onBlur={() => saveApp()} onChange={(event: any) => setAppData({ ...appData, name: event.target.value })} />
                            </div>
                        </div>

                        <div className="card card-style">
                            <div className="content my-0">
                                <h5 className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0">Apps</h5>
                                <div className="list-group list-custom-small list-icon-0">
                                    {apps.length > 0 && apps.map((x: any) => {
                                        return <>
                                            <a onClick={() => loadApp(x._id)} className={`add-page-link cursor-pointer ${appData._id == x._id ? 'button-active' : ''}`}>
                                                <i className={`fa font-12 fa-home gradient-green rounded-sm color-white`}></i>
                                                <span> {x.name}</span>
                                                <i className="fa fa-angle-right"></i>
                                            </a>
                                        </>
                                    })}
                                    {apps.length < 3 &&
                                        <a className="add-page-link cursor-pointer" onClick={() => addApp()}>
                                            <i className={`fa font-12 fa-plus gradient-blue rounded-sm color-white`}></i>
                                            <span>Add New App</span>
                                            <i className="fa fa-angle-right"></i>
                                        </a>}
                                    {apps.length >= 3 && <a className="add-page-link cursor-pointer" onClick={() => setDisplayUpgradeModal(true)}>
                                        <i className={`fa font-12 fa-crown gradient-teal rounded-sm color-white`}></i>
                                        <span> Upgrade To Add More</span>
                                        <i className="fa fa-angle-right"></i>
                                    </a>}
                                </div>
                            </div>
                        </div>

                        <div className="card card-style">
                            <div className="content my-0">
                                <h5 className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0">Pages</h5>
                                <div className="list-group list-custom-small list-icon-0">
                                    {pages && pages.length > 0 && pages.map((x: any) => {
                                        return <>
                                            <a onClick={() => loadPage(x._id)} className={`add-page-link cursor-pointer ${pageData._id == x._id ? 'button-active' : ''}`}>
                                                <i className={`fa font-12  fa-file gradient-blue rounded-sm color-white`}></i>
                                                <span> {x.name}</span>
                                                <i className="fa fa-angle-right"></i>
                                            </a>
                                        </>
                                    })}
                                    {pages.length < 10 &&
                                        <a className="add-page-link cursor-pointer" onClick={() => addPage()}>
                                            <i className={`fa font-12 fa-plus gradient-blue rounded-sm color-white`}></i>
                                            <span>Add New Page</span>
                                            <i className="fa fa-angle-right"></i>
                                        </a>}
                                    {pages.length >= 10 && <a className="add-page-link cursor-pointer" onClick={() => setDisplayUpgradeModal(true)}>
                                        <i className={`fa font-12 fa-crown gradient-teal rounded-sm color-white`}></i>
                                        <span> Upgrade To Add More</span>
                                        <i className="fa fa-angle-right"></i>
                                    </a>}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="main-container-wrapper">

                    <div className="main-container">

                        <div className="left-bar">
                            <div style={{ margin: '15px' }}>
                                <h5 className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0" style={{ color: 'black', textAlign: 'left' }}>Page Name</h5>
                                <div className="input-style input-style-always-active has-borders">
                                    <input type="text" className="form-control" value={pageData.name} onBlur={() => savePage(true)} onChange={(event: any) => setPageData({ ...pageData, name: event.target.value })} />
                                </div>
                            </div>

                            <div>
                                <div className="card card-style"><span className="font-700 text-uppercase opacity-80 font-16 pt-2 mb-0">Add Blocks</span>
                                    <p>Click on a block below to add it to your app</p>
                                </div>
                            </div>

                            <div style={{ overflow: 'scroll', maxHeight: '80%' }}>
                                <div className="card card-style rounded-m no-user-select add-block-button cursor-pointer" onClick={() => addBlock("basic-card")}>
                                    <div className="content mb-0">
                                        <h1 className="text-center mb-0">Lorem Ipsum</h1>
                                        <p className="text-center color-highlight font-11 mt-n1">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                        <p className="boxed-text-xl mt-n3">
                                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                    </div>
                                </div>

                                <div className="card card-style rounded-m shadow-l mx-3 no-user-select add-block-button cursor-pointer" onClick={() => addBlock("image-card")}>
                                    <div className="card-bottom text-center mb-0">
                                        <h1 className="color-white font-700 mb-n1">Lorem Ipsum</h1>
                                        <p className="color-white opacity-80 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                    </div>
                                    <div className="card-overlay bg-gradient"></div>
                                    <img className="img-fluid" src="https://source.unsplash.com/random" />
                                </div>

                                <div className="card card-style no-user-select add-block-button  cursor-pointer rounded-m" onClick={() => addBlock("event")}>
                                    <div className="card shadow-l mb-0" style={{ height: '150px', backgroundImage: `url(https://source.unsplash.com/random)` }}>
                                        <div className="card-bottom ms-3">
                                            <p className="color-white font-10 opacity-80 mb-n1"><i className="far fa-calendar"></i> August <i className="ms-3 far fa-clock"></i> 10am</p>
                                            <p className="color-white font-10 opacity-80 mb-2"><i className="fa fa-map-marker-alt"></i> London</p>
                                        </div>
                                        <div className="card-overlay bg-gradient opacity-90 rounded-0"></div>
                                    </div>
                                    <div className="content mb-0">
                                        <div className="float-start">
                                            <h1 className="mb-n1">Lorem Ipsum</h1>
                                            <p className="font-10 mb-2 pb-1">Dolor sit amet, consectetur adipiscing elit</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-style no-user-select add-block-button rounded-m cursor-pointer" onClick={() => addBlock("meeting")}>
                                    <div className="content mb-4">
                                        <h3>Lorem Ipsum</h3>
                                        <p>
                                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="divider mb-3"></div>
                                        <div className="row mb-0">
                                            <div className="col-md-6">
                                                <p className="text-center mb-0 pb-3"><i className="fa fa-calendar-alt me-2 color-yellow-dark"></i>January</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="text-end mb-0 pb-3"><i className="fa fa-clock me-2 color-green-dark"></i>11:00 AM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                        <div className="center-box">

                            <div className="app-container">
                                <div style={{ height: '100%' }} className="theme-light" data-highlight="highlight-red" data-gradient="body-default">

                                    {/* <div id="preloader"><div className="spinner-border color-highlight" role="status"></div></div> */}

                                    <div style={{ height: '100%' }} id="page">
                                        <div style={{ height: '100%' }} className="page-content">
                                            <div className="phone">
                                                {pageData.layout && <GridLayout
                                                    className="layout"
                                                    resizeHandles={["s", "n", "e", "w", "ne", "nw", "se", "sw"]}
                                                    layout={pageData.layout}
                                                    cols={4}
                                                    isResizable={true}
                                                    rowHeight={10}
                                                    onLayoutChange={(layout) => { setPageData({ ...pageData, layout: layout }); savePageOnReorder({ ...pageData, layout: layout }); }}
                                                    isBounded={true}
                                                >
                                                    {pageData.layout.map((x: any) => <div key={x.i} className={selectedBlock ? `${`block${selectedBlock.id}` === x.i ? 'active-block' : ''}` : ''}>
                                                        {renderBlock(pageData.elements.find((y: any) => `block${y.id}` === x.i))}
                                                    </div>)}
                                                </GridLayout>}
                                            </div>



                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="right-bar">
                            {selectedBlock && selectedBlock.type && <>

                                <div className="card card-style rounded-m">
                                    <div className="content mb-0">
                                        <span className="font-700 text-uppercase opacity-40 font-12 pt-2 mb-0">Edit Block</span>
                                        <div style={{ padding: '20px' }}>{renderEditForm()}</div>
                                    </div>
                                </div>


                            </>}


                        </div>

                    </div>
                </div>
            </div>
            {displayUpgradeModal && <div onClick={() => setDisplayUpgradeModal(false)} className="screen-cover"></div>}
            {displayUpgradeModal && <div id="menu-contact-1" className="menu menu-box-bottom menu-box-detached rounded-m upgrade-modal" style={{ margin: '50px', padding: '20px' }} data-menu-effect="menu-over" data-menu-height="460">



                <div className="menu-title mt-n1">
                    <h1>Upgrade</h1>
                    <a onClick={() => setDisplayUpgradeModal(false)} className="close-menu"><i className="fa fa-times"></i></a>
                </div>
                <div className="content">
                    <h3 className="font-700">Upgrade To Pro</h3>
                    <p className="">
                        Upgrade today to get access to custom development, expert MVP development help, and a huge number of extra features.
                    </p>
                    <div className="row mb-0">
                        <div className="col-6">
                            <ul className="icon-list">
                                <li><i className="fa fa-check color-green1-dark"></i>Custom Domain</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Web Applications</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Landing Page</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Rich Data Structure</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Manage Your Data</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Publish To App Stores</li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul className="icon-list">
                                <li><i className="fa fa-check color-green1-dark"></i>Dashboards</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Complex Forms</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Custom Blocks</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Expert MVP Help</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Payment Gateway</li>
                                <li><i className="fa fa-check color-green1-dark"></i>Dynamic Data Components</li>
                            </ul>
                        </div>
                    </div>
                    <p className="">
                        Speak to one of our team today to find out more about Verticode MVP builder Pro.
                    </p>
                    <div className="row">
                        <div className="col-6"><a className="m-3 btn btn-full btn-m font-900 text-uppercase rounded-sm shadow-l bg-blue-dark" target="_blank" href="https://calendly.com/verticode/30min?month=2022-09">Book a Meeting</a></div>
                        <div className="col-6"><a className="m-3 btn btn-full btn-m font-900 text-uppercase rounded-sm shadow-l bg-blue-dark cursor-pointer" onClick={() => requestCallback()}>Request More Information</a></div>
                    </div>
                </div>
            </div>}
            {displayRequestedInfoModal && <div onClick={() => setDisplayRequestedInfoModal(false)} className="screen-cover"></div>}
            {displayRequestedInfoModal && <div id="menu-contact-1" className="menu menu-box-bottom menu-box-detached rounded-m upgrade-modal" style={{ margin: '200px', padding: '20px' }} data-menu-effect="menu-over" data-menu-height="460">
                <div className="menu-title mt-n1">
                    <a onClick={() => setDisplayRequestedInfoModal(false)} className="close-menu"><i className="fa fa-times"></i></a>
                </div>
                <div className="content">
                    <h3 className="font-700">More info requested</h3>
                    <p className="">
                        We'll be in touch soon!
                    </p>
                </div>
            </div>}
        </>



    );
}

export default Editor;
