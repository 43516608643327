import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import './styles/style.css';
import './styles/bootstrap.css';
import './fonts/css/fontawesome-all.min.css';
import '../node_modules/react-resizable/css/styles.css'
import '../node_modules/react-grid-layout/css/styles.css'
import Editor from './Editor';
import Login from './Login';
import Register from './Register';
export const UserContext = React.createContext({} as any);
function App() {
  const [user, setUser] = useState({} as any);
  const value: any = { user, setUser };

  return (
    <>
      <UserContext.Provider value={value}>
        <BrowserRouter>
          <Routes>

            <Route path='/' element={
              <ProtectedRoute user={user}>
                <Editor />
              </ProtectedRoute>
            } />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />

          </Routes>
        </BrowserRouter>
      </UserContext.Provider>

    </>
  );
}

const ProtectedRoute = ({ user, children }: any) => {
  console.log(user)
  if (!user || !user.token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default App;
